import * as React from "react";
import type { HeadFC, PageProps } from "gatsby";

import "../styles/index.scss";

import { GridStyleGuide } from "../components/GridStyleGuide/index.js";
import { JungleHero } from "../components/JungleHero";
import { Hero } from "../components/Hero";
import { Slider } from "../components/Slider";

import {
  Config,
  Header,
  Footer,
  Newsletter,
  ContentBlocks,
  PricingThree,
} from "@wetransfer/wetransfer-web-ui/src/index";

import pricingData from "../data/pricing.json";
import contentBlocksData from "../data/content-blocks.json";

//IMPORTANT NOTE: Since this is a temporary page with a tight deadline, this is a rush build and code should not be re-used.
//TODO: Check typescript errors

const IndexPage: React.FC<PageProps> = () => {
  return (
    <main>
      <GridStyleGuide />
      <Config
        gatsby
        snowPlowCategory="brand_home"
        language={"en-US"}
        languages={[]}
        changeLanguage={""}
        baseUrl="https://wetransfer.com"
      />
      <Header theme="dark" />
      <Hero />
      <div className="spacing" />
      <ContentBlocks
        items={contentBlocksData}
        theme="light"
        titleDisplayType="heading"
        paddingBottom="large"
      />
      <div className="line">
        <div className="line__inner" />
      </div>
      <Slider />
      <JungleHero />
      <PricingThree
        title="Choose a plan"
        description="Whatever it is you do, we have a plan for moving your ideas"
        cards={pricingData}
        continent="NA"
        country="US"
      />
      <Newsletter
        heading={" Sign up for the latest on how WeTransfer keeps ideas moving"}
        consentText={"Privacy Policy"}
        consentUrl={"https://wetransfer.com/legal/privacy"}
        showFirstName={true}
        showLastName={true}
        theme={"dark"}
        signupSourceWt="Move it Footer"
      />
      <Footer />
    </main>
  );
};

export default IndexPage;

export const Head: HeadFC = () => (
  <>
    <title>WeTransfer - Send big files the easy way - Free up to 2GB</title>
    <meta
      name="description"
      content="WeTransfer is the easy way to send your big files around the world. Transfer files, photos, projects of any size. It's free and requires no sign up anything under 2GB."
    />
    {/* Open Graph */}
    <meta
      property="og:title"
      content="WeTransfer - Send big files the easy way - Free up to 2GB"
    />
    <meta
      property="og:site_name"
      content="WeTransfer - Send big files the easy way - Free up to 2GB"
    />
    <meta property="og:url" content="moveit.wetransfer.com" />
    <meta
      property="og:description"
      content="WeTransfer is the easy way to send your big files around the world. Transfer files, photos, projects of any size. It's free and requires no sign up anything under 2GB."
    />
    {/* Twitter */}
    <meta name="twitter:card" content="summary_large_image" />
    <meta name="twitter:creator" content="WeTransfer" />
    <meta name="twitter:site" content="moveit.wetransfer.com" />
    <meta
      name="twitter:title"
      content="WeTransfer - Send big files the easy way - Free up to 2GB"
    />
    <meta
      name="twitter:description"
      content="WeTransfer is the easy way to send your big files around the world. Transfer files, photos, projects of any size. It's free and requires no sign up anything under 2GB."
    />
    {/* Image */}
    <meta
      property="og:image"
      content="https://moveit.wetransfer.com/images/meta.jpg"
    />
    <meta property="og:image:alt" content="WeTransfer x Jungle meta image" />
    {/* Google Tag Manager - Server Side Container */}
    <script
      dangerouslySetInnerHTML={{
        __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                  new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                  j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                  'https://tagging.wetransfer.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                })(window,document,'script','dataLayer','GTM-NS54WBW');`,
      }}
    />

    {/* Favicons */}
    <link
      rel="icon"
      sizes="16x16 32x32"
      href="https://prod-cdn.wetransfer.net/packs/media/images/favicon-a34a7465.ico"
    />
    <link
      rel="mask-icon"
      href="https://prod-cdn.wetransfer.net/packs/media/images/favicon-5a543f69.svg"
      color="#17181A"
    />
    <link
      rel="apple-touch-icon-precomposed"
      href="https://prod-cdn.wetransfer.net/packs/media/images/apple-touch-icon-bd1315cf.png"
    />
    <link
      rel="apple-touch-icon-precomposed"
      sizes="152x152"
      href="https://prod-cdn.wetransfer.net/packs/media/images/apple-touch-icon-152x152-precomposed-58e4c998.png"
    />
    <link
      rel="apple-touch-icon-precomposed"
      sizes="180x180"
      href="https://prod-cdn.wetransfer.net/packs/media/images/apple-touch-icon-180x180-precomposed-fd272b89.png"
    />
    <link
      rel="apple-touch-icon-precomposed"
      sizes="167x167"
      href="https://prod-cdn.wetransfer.net/packs/media/images/apple-touch-icon-167x167-precomposed-976d4eb8.png"
    />

    <body>
      {/* Google Tag Manager - Server Side Container - No Script */}
      <noscript
        dangerouslySetInnerHTML={{
          __html: `<iframe src="https://tagging.wetransfer.com/ns.html?id=GTM-NS54WBW" height="0" width="0" style="display:none;visibility:hidden"></iframe>`,
        }}
      />
    </body>
  </>
);
