import React, { useEffect, useState } from 'react';
import './style.scss';

export const GridStyleGuide = () => {
  const [length, setLength] = useState();
  const [active, setActive] = useState(false);

  const setGrid = () => {
    const mql = window.matchMedia('(min-width: 768px)');
    mql.matches ? setLength(12) : setLength(6);
  };

  useEffect(() => {
    setGrid();
    window.addEventListener('resize', setGrid);
    window.addEventListener('keypress', (e) => {
      if (e.key === 'g' && e.ctrlKey) {
        active ? setActive(false) : setActive(true);
      }
    });
  }, [active]);

  return (
    <div className={`grid ${active && 'grid--active'}`}>
      {Array.from({ length }).map((_, i) => (
        <div key={i}></div>
      ))}
    </div>
  );
};
