import React, { useState } from "react";
import { TextBlock, UIImage } from "@wetransfer/wetransfer-web-ui/src/index";
import "./style.scss";
import { track } from "../../helpers/track";

import blocks from "./data.json";

export const Slider = () => {
  const [active, setActive] = useState(0);

  return (
    <div className="slider__container">
      <div className="slider">
        <div className="slider__text">
          <div className="slider__inner">
            {blocks.map((el, index) => (
              <div
                className={`slide ${active === index ? "slide--active" : ""}`}
                key={index}
              >
                <TextBlock
                  title={el.title}
                  text={el.text}
                  type={el.type}
                  theme="dark"
                />
              </div>
            ))}
          </div>
          <div className="slider__nav">
            <svg
              onClick={() => {
                setActive(active === 0 ? blocks.length - 1 : active - 1);
                track("brand_home", "testimonials_previous");
              }}
              className="slider__nav--left"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 40 40"
            >
              <path
                d="m17.386 25 5-5-5-5"
                stroke="#161616"
                strokeWidth="2"
                strokeLinecap="round"
              />
            </svg>
            <svg
              onClick={() => {
                setActive(active === blocks.length - 1 ? 0 : active + 1);
                track("brand_home", "testimonials_next");
              }}
              className="slider__nav--right"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 40 40"
            >
              <path
                d="m17.386 25 5-5-5-5"
                stroke="#161616"
                strokeWidth="2"
                strokeLinecap="round"
              />
            </svg>
          </div>
        </div>
        <div className="slider__image">
          {blocks.map((el, index) => (
            <div
              className={`slider__image__inner ${
                active === index ? "active" : ""
              }`}
            >
              <UIImage
                imageUrl={el.image.url}
                imageAlt={el.image.alt}
                borderRadius="medium"
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
