import React from "react";
import "./style.scss";
import { track } from "../../helpers/track";
import { StaticImage } from "gatsby-plugin-image";

export const JungleHero = () => {
  return (
    <div className="jungle-hero jungle-hero--two">
      <div className="jungle-hero__left">
        <div className="jungle-hero__left__content">
          <img
            className="jungle-hero__logo"
            src="./images/logos.svg"
            alt="WeTransfer x Jungle"
          />
          <h2>
            Think it. <br /> Make it.
            <br /> Move it.
          </h2>
          <div>
            <p>
              Big ideas make big files.
              <br /> Send them the easy way.
            </p>
            <a
              onClick={() => {
                track("brand_home", "hero_bottom_check_out");
              }}
              href="https://junglejunglejungle.wetransfer.com"
            >
              Try it now
            </a>
          </div>
        </div>
        <StaticImage
          className="jungle-hero__bg"
          src="../../images/hero-1.jpg"
          alt="Jungle impression"
          layout="fullWidth"
        />
      </div>
      <div className="jungle-hero__right">
        <div className="jungle-hero__right__top">
          <StaticImage
            className="jungle-hero__bg"
            src="../../images/hero-2.jpg"
            alt="Jungle impression"
            layout="fullWidth"
          />
        </div>
        <div className="jungle-hero__right__bottom">
          <StaticImage
            className="jungle-hero__bg"
            src="../../images/hero-3.jpg"
            alt="Jungle impression"
            layout="fullWidth"
          />
        </div>
      </div>
    </div>
  );
};
