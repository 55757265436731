import { trackStructEvent } from "@snowplow/browser-tracker";

export const track = (category = "brand_home", label) => {
  if (label) {
    window.dataLayer &&
      window.dataLayer.push({
        event: "event",
        category: category,
        action: "click",
        label: label,
      });
    trackStructEvent({
      category: category,
      action: `click_${label}`,
      label: label,
      property: "",
      value: "",
    });
  }
};
